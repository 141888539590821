import { ShareResourceEnum } from 'modules/share/enum/share-resource-enum'
import { ShareResourceInterface } from 'modules/share/types/share-interface'
import { getResourceApiSlug } from 'modules/share/utils/get-resource-api-slug'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { ErrorResponseInterface } from 'shared/types/error-response-content-interface'
import useSWR from 'swr'

export const useShareResource = ({
  hash,
  type,
  badRequestHandler,
}: {
  hash?: string
  type?: ShareResourceEnum
  badRequestHandler: (e: ErrorResponseInterface) => void
}) => {
  const resourceApiSlug = getResourceApiSlug(type)
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    ShareResourceInterface
  >({ method: RequestMethodsEnum.get, badRequestHandler })
  return useSWR(() => hash && resourceApiSlug && `/api/${resourceApiSlug}/share/${hash}`, fetcher)
}
